import { Injectable, computed, signal } from '@angular/core';
import { BaseService } from '../../../../../../../shared/src/lib/services/base.service';
import {
  EAgentCategory,
  ECandidateDocument,
  ICandidate,
  ICandidatePinUpdate,
  ICandidateSQ,
  ISaveCandidate,
  IValidateCandidate,
  IValidateCandidatePinUpdate,
  IValidateCandidateResp,
} from './candidate.model';
import { ISR } from '../../../../../../../shared/src/lib/models/index.model';
import { Observable, concat, map, mergeMap, of, switchMap, tap, throwError, timer } from 'rxjs';
import {
  CustomValidationError,
  ICodeTitle,
  IFormSchema,
  IFormSchema2,
  ISearchFormSchema,
  TableCol,
} from 'ets-fe-ng-sdk';
import { CompanyFacadeService } from '../../../../../../../shared/src/lib/facades/company.facade.service';
import { AbstractControl } from '@angular/forms';
import { CodeFacadeService } from '../../../set-ups/parameters/code.facade.service';
import { UtilityService } from '../../../../../../../shared/src/lib/services/utility.service';
import {
  IUploadSchema,
  IUploadSubmissionResp,
  IValidationResp2,
} from '../../../../../../../shared/src/lib/components/batch-uploads/batch-uploads-comps/batch-upload-input/batch-upload-input.model';
import { DocumentService } from '../../../../../../../shared/src/lib/services/document.service';
import { ERefCat } from '../../../../../../../shared/src/lib/models/document.model';
import { AuthenticationService } from '../../../../../../../shared/src/lib/authentication/authentication.service';
import { environment } from '../../../../../../../shared/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CandidateService extends BaseService {
  readonly documentsToUpload = computed<{
    [x in ECandidateDocument]: { title: string; manadatory?: boolean };
  }>(() => ({
    [ECandidateDocument.passportPhotograph]: { title: 'Passport Photograph', manadatory: true },
    [ECandidateDocument.identityCard]: { title: 'Identity Card', manadatory: true },
    [ECandidateDocument.secondarySchoolLeavingCertificate]: {
      title: 'Secondary School Leaving Certificate',
      manadatory: false,
    },
    [ECandidateDocument.firstDegreeCertificate]: { title: 'First Degree Certificate', manadatory: false },
    [ECandidateDocument.taxClearance]: { title: 'Tax Clearance', manadatory: false },
  }));

  readonly documentsToUploadList = computed<
    { title: string; accepts?: string; code: ECandidateDocument; mandatory: boolean; useWebcam: boolean }[]
  >(() =>
    Object.entries(this.documentsToUpload()).map(([code, config]) => ({
      code: code as ECandidateDocument,
      title: config.title,
      useWebcam: code == ECandidateDocument.passportPhotograph,
      mandatory: config.manadatory || false,
      accepts: '.png,.jpg,.jpeg,.bmp' + (code == ECandidateDocument.passportPhotograph ? '' : ',.pdf,.docx'),
    })),
  );

  readonly documentsToUploadMap = computed(() => this.documentsToUploadList()?.toMap('code'));

  readonly agentCategories = Object.entries(EAgentCategory).map<ICodeTitle>(([key, code]) => ({
    code,
    title: this.uS.toTitleCase(key.toSentenceCase()),
  }));

  protected readonly _formFieldsMap = computed<{
    [k in keyof ISaveCandidate]?: IFormSchema2<ISaveCandidate>;
  }>(() => ({
    firstName: { field: 'firstName', label: 'First Name', noFormat: true },
    lastName: { field: 'lastName', label: 'Last Name', noFormat: true },
    middleName: { field: 'middleName', label: 'Middle Name', noFormat: true },
    agentCategory: {
      field: 'agentCategory',
      label: 'Agent Category',
      type: 'autocomplete',
      optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('AGENT_CATEGORY'),
      valueField: 'code',
      labelType: 'ct',
    },
    redgeBasis: {
      field: 'redgeBasis',
      label: 'Registration Basis',
      type: 'autocomplete',
      optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('AGENT_REG_BASIS'),
      valueField: 'code',
      labelType: 'ct',
    },
    birthDt: {
      field: 'birthDt',
      label: 'Date of Birth',
      type: 'date',
      max: new Date().setFullYear(new Date().getFullYear() - 18),
    },
    highestEduLevel: {
      field: 'highestEduLevel',
      label: 'Highest Education Level',
      type: 'autocomplete',
      optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('EDU_LEVEL'),
      valueField: 'code',
      labelType: 'ct',
    },
    nin: this.uS.ninFormField() as IFormSchema2<ISaveCandidate>,
    personalEmail: {
      field: 'personalEmail',
      label: 'Personal Email',
      type: 'email',
      noFormat: true,
      showValidation: true,
    },
    personalPhoneNo: {
      field: 'personalPhoneNo',
      label: 'Personal Phone No.',
      type: 'tel',
      showValidation: true,
    },
    company: {
      field: 'company',
      label: 'Sponsor Company',
      type: 'autocomplete',
      optionsInitFunc2: this.companyFacade.selectAllForInput,
      valueField: 'code',
      labelType: 'ct',
    },
    stateOfOrigin: {
      field: 'stateOfOrigin',
      label: 'State Of Origin',
      type: 'autocomplete',
      optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('NIGERIAN_STATES'),
      valueField: 'code',
      labelType: 'ct',
    },
    maritalStatus: {
      field: 'maritalStatus',
      label: 'Marital Status',
      type: 'autocomplete',
      optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('MARITAL_STATUS'),
      valueField: 'code',
      labelType: 'ct',
    },
    nationality: {
      field: 'nationality',
      label: 'Nationality',
      type: 'autocomplete',
      optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('NATIONALITY'),
      valueField: 'code',
      labelType: 'ct',
    },
    employmentDate: { field: 'employmentDate', label: 'Employment Date', type: 'date', max: Date.now() },
    externalRef: {
      field: 'externalRef',
      label: 'External Reference',
      hint: 'If you already have an Agent identification Number issued by your employer, please enter it here.',
    },
    officeEmail: {
      field: 'officeEmail',
      label: 'Office Email',
      type: 'email',
      noFormat: true,
      showValidation: true,
    },
    officePhoneNo: { field: 'officePhoneNo', label: 'Office Phone No.', type: 'tel' },
    examPass: { field: 'examPass', label: 'Exam Pass', type: 'checkbox' },
    ria: { field: 'ria', label: 'RIA', type: 'text' },
    agentAddress: { field: 'agentAddress', label: 'Address', type: 'text' },
    agentAddressState: {
      field: 'agentAddressState',
      label: 'Address State',
      type: 'autocomplete',
      optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('NIGERIAN_STATES'),
      valueField: 'code',
      labelType: 'ct',
    },
  }));
  readonly formFieldsMap = computed(
    () => this._formFieldsMap() as { [k in keyof ISaveCandidate]?: IFormSchema },
  );

  readonly displayedColumns = (viewRoute: string = '../view?code=') =>
    signal<TableCol<ICandidate>[]>([
      { f: 'candidateNo', t: 'Candidate No.', routeFormatter: (r) => `${viewRoute}${r.candidateNo}` },
      // { f: 'firstName', t: 'First Name' },
      // { f: 'middleName', t: 'Middle Name' },
      // { f: 'lastName', t: 'Last Name' },
      { f: 'fullName', t: 'Full Name', formatter: (v: string) => v?.removeNull() },
      {
        f: 'company',
        t: 'Company',
        formatter: this.companyFacade.fetchDescriptionByCode,
        additionalColumn: true,
      },
      {
        f: 'highestEduLevel',
        t: 'Highest Edu. Level',
        formatter: this.codeFacade.fetchTitleByCodeAndSubgroupFactory('EDU_LEVEL'),
      },
      { f: 'personalPhoneNo', t: 'Personal Phone No.' },
      { f: 'officePhoneNo', t: 'Office Phone No.' },
      { f: 'personalEmail', t: 'Personal Email' },
      { f: 'officeEmail', t: 'Office Email' },
      // { f: 'docKey', t: 'Doc Key' },
      { f: 'nin', t: 'NIN' },
      { f: 'birthDt', t: 'Birth Date', formatter: this.uS.dateFormat },
      { f: 'externalRef', t: 'External Ref.' },
    ]);

  readonly searchSchema = (viewCandidateAction?: (cno: string) => any) =>
    signal<{ [k in keyof ICandidateSQ]: ISearchFormSchema<ICandidateSQ> }>({
      candidateNo: {
        field: 'candidateNo',
        label: 'Candidate No.',
        standalone: !!viewCandidateAction,
        asyncValidators: [this.asyncValidateNo.bind(this)],
        action: viewCandidateAction ? (form, cell) => viewCandidateAction(cell) : undefined,
      },
      fullname: { field: 'fullname', label: 'Full name' },
      personalEmail: { field: 'personalEmail', label: 'Personal Email' },
      officeEmail: { field: 'officeEmail', label: 'Office Email' },
      approved: { field: 'approved', label: 'Approved', type: 'checkbox' },
      examPass: { field: 'examPass', label: 'Exam Pass', type: 'checkbox' },
      ciinRedg: { field: 'ciinRedg', label: 'CIIN Redg.', type: 'checkbox' },
      agentCategory: {
        field: 'agentCategory',
        label: 'Agent Category',
        type: 'autocomplete',
        optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('AGENT_CATEGORY'),
        valueField: 'code',
        labelType: 'ct',
      },
      redgeBasis: {
        field: 'redgeBasis',
        label: 'Registration Basis',
        type: 'autocomplete',
        optionsInitFunc2: this.codeFacade.fetchBySubgroupFactory('AGENT_REG_BASIS'),
        valueField: 'code',
        labelType: 'ct',
      },
      company: {
        field: 'company',
        label: 'Company',
        type: 'autocomplete',
        optionsInitFunc2: this.companyFacade.selectAllForInput,
        valueField: 'code',
        labelType: 'ct',
      },
      certificateIssued: { field: 'certificateIssued', label: 'Certificate Issued', type: 'checkbox' },
      naicomRedg: { field: 'naicomRedg', label: 'NAICOM Redg.', type: 'checkbox' },
      pin: { field: 'pin', label: ' ' },
      createdFrom: { field: 'createdFrom', label: 'Created From', type: 'date' },
      createdTo: { field: 'createdTo', label: 'Created To', type: 'date' },
      examPassFrom: {
        field: 'examPassFrom',
        label: 'Exam Pass From',
        type: 'date',
      },
      examPassTo: {
        field: 'examPassTo',
        label: 'Exam Pass To',
        type: 'date',
      },
    });

  readonly uploadSchema = signal<{ [k in keyof ICandidate]?: IUploadSchema<ICandidate> }>({
    firstName: { field: 'firstName', label: 'First Name' },
    middleName: { field: 'middleName', label: 'Middle Name' },
    lastName: { field: 'lastName', label: 'Last Name' },
    personalEmail: { field: 'personalEmail', label: 'Personal Email' },
    personalPhoneNo: { field: 'personalPhoneNo', label: 'Personal Phone No' },
    officeEmail: { field: 'officeEmail', label: 'Office Email' },
    officePhoneNo: { field: 'officePhoneNo', label: 'Office Phone No' },
    // examPass: { field: 'examPass', label: 'Exam Pass', type: 'checkbox' },
    // ciinRedg: { field: 'ciinRedg', label: 'CIIN Redg', type: 'checkbox' },
    company: {
      field: 'company',
      label: 'Company',
    },
    // approved: { field: 'approved', label: 'Approved', type: 'checkbox' },
    // certificateIssued: { field: 'certificateIssued', label: 'Certificate Issued', type: 'checkbox' },
    // naicomRedg: { field: 'naicomRedg', label: 'NAICOM Redg', type: 'checkbox' },
    nin: { field: 'nin', label: 'NIN' },
    birthDt: { field: 'birthDt', label: 'Birth Date', type: 'date' },
    externalRef: { field: 'externalRef', label: 'External Ref' },
    employmentDate: { field: 'employmentDate', label: 'Employment Date', type: 'date' },
    highestEduLevel: { field: 'highestEduLevel', label: 'Highest Edu Level' },
    // agentCategory: { field: 'agentCategory', label: 'Agent Category' },
    // redgeBasis: { field: 'redgeBasis', label: 'Registration Basis' },
    // pin: { field: 'pin', label: this.uS.customLabels.pin.t  },
  });

  protected override baseURL = `v1/cifm/candidates/`;

  constructor(
    public companyFacade: CompanyFacadeService,
    public documentService: DocumentService,
    public codeFacade: CodeFacadeService,
    public authenticationService: AuthenticationService,
    public uS: UtilityService,
  ) {
    super();
  }

  search = (query: ICandidateSQ) => this.get<ISR<ICandidate>>('search', query);
  findByCandidateNo = (candidateNo: string) => {
    return this.search({ candidateNo }).pipe(map((r) => r.content?.[0]));
  };

  save = (data: ISaveCandidate, files: SaveDocParameter) => {
    const docMap = this.documentsToUpload();
    return (data.id ? this.update([data]) : this.saveCandidates([data]))
      .pipe(
        map((r) => r?.[0]),
        map((c) => {
          if (c?.message && c.message != 'Success') throw c.message;
          return c;
        }),
      )
      .pipe(
        mergeMap((c) =>
          this.saveCandidateDocuments({ candidateNo: c.candidateNo, companyCode: c.company, files }).pipe(
            map((documents) => ({
              candidate: c,
              documents,
              documentErrorMesssage:
                documents.errors
                  .map(
                    (x) => docMap[x.reqMeta.category! as ECandidateDocument]?.title + '\n' + x.errorMessage,
                  )
                  .join('\n\n') || undefined,
            })),
          ),
        ),
      );
  };

  setup = (data: ISaveCandidate) => {
    return this.post<ICandidate>(data, 'signup');
  };

  saveCandidateDocuments = (group: { candidateNo: string; companyCode?: string; files: SaveDocParameter }) =>
    this.documentService.updateDocuments(
      Object.entries(group.files)
        .filter((x) => x[1])
        .map(([code, file]) => ({
          file: file!,
          meta: {
            refCat: ERefCat.candidate,
            refNo: group.candidateNo,
            // companyCode: group.companyCode,
            category: code,
          },
        })),
    );

  getPassport = (candidateNo: string) => {
    return this.documentService.getDocumentLink({
      refNo: candidateNo,
      refCat: ERefCat.candidate,
      category: ECandidateDocument.passportPhotograph,
    });
  };

  readonly saveCandidates = (data: ISaveCandidate[]) => this.post<ICandidate[]>(data);

  protected uploadCandidates = signal((data: ISaveCandidate[]) =>
    this.saveCandidates(data).pipe(
      mergeMap((r) => this.update(r.map((x) => ({ ...x, approved: true })))),
      map<ICandidate[], IUploadSubmissionResp>((r) => ({ reportArray: r })),
    ),
  );

  update(data: ISaveCandidate[]) {
    return this.put<ICandidate[]>(data).pipe(
      map((r) => r),
      tap(() => {
        if (environment.isCandidate) this.authenticationService.getFromOnline();
      }),
    );
  }

  deleteCandidates(ids: number[]) {
    return concat(...ids.map((id) => this.delete(id + '')));
  }

  certificateGeneration(data: ICandidate[]) {
    return this.post(data);
  }

  pinUpdate = (data: ICandidatePinUpdate) =>
    this.bulkPinValidate([{ ...data, rowId: '1' }]).pipe(
      map((r) => r.data?.[0].pin),
      mergeMap((message) =>
        message == null ? this.bulkPinUpdate([data]) : throwError(() => new Error(message)),
      ),
    );

  bulkPinUpdate = (data: ICandidatePinUpdate[]) => this.apiS.put('v1/cifm/ciin-pin/update', data);

  bulkPinValidate = (data: IValidateCandidatePinUpdate[]) =>
    this.apiS
      .post<IPinValidation[]>('v1/cifm/validate/ciin/pin', {
        validateCIINPINRequestList: data,
      })
      .pipe(map((r) => <IValidationResp2<IPinValidation>>{ data: r, rowIDField: 'rowId' }));

  validateMigration = (data: IValidateCandidate[]) =>
    this.apiS
      .post<IValidateCandidateResp[]>('v1/cifm/validate/candidate', {
        validateCifmCandidateList: data,
      })
      .pipe(
        map(
          (r) =>
            <IValidationResp2<IValidateCandidate>>{
              data: r.map((row) => ({
                approved: undefined,
                highestEduLevel: !row.highestEduLevel ? `Value is invalid` : undefined,
                nin: !row.nin ? `NIN already exists` : undefined,
                officeEmail: !row.officeEmail ? `Office Email already exists` : undefined,
                officePhoneNo: !row.officePhoneNo ? `Office Phone Number already exists` : undefined,
                personalEmail: !row.personalEmail ? `Personal Email already exists` : undefined,
                personalPhoneNo: !row.personalPhoneNo ? `Personal Phone Number already exists` : undefined,
              })),
              rowIDField: 'rowId',
            },
        ),
      );

  checkIfValueExists =
    <TKey extends keyof IValidateCandidate>(fieldName: TKey) =>
    (currentValue: string | undefined, extraData?: Partial<IValidateCandidate>) =>
    (control: AbstractControl<IValidateCandidate[TKey]>): Observable<CustomValidationError | null> =>
      control.value == null || control.value == currentValue
        ? of(null)
        : timer(600).pipe(
            switchMap(() =>
              this.validateMigration([
                {
                  ...(extraData || {}),
                  [fieldName]: control.value,
                } as Partial<IValidateCandidate> as IValidateCandidate,
              ]).pipe(
                map((r) => r.data?.[0]),
                map((row) => {
                  // debugger;
                  const responseValue = row?.[fieldName];
                  return responseValue ? { custom: responseValue as string } : null;
                }),
              ),
            ),
          );

  validateIfNINExists = this.checkIfValueExists('nin');

  validateIfOfficeEmailExists = this.checkIfValueExists('officeEmail');

  validateIfOfficePhoneNoExists = this.checkIfValueExists('officePhoneNo');

  validateIfPersonalEmailExists = this.checkIfValueExists('personalEmail');

  validateIfPersonalPhoneNoExists = this.checkIfValueExists('personalPhoneNo');

  asyncValidateNo = (control: AbstractControl): Observable<CustomValidationError | null> => {
    const val = String(control?.value)
      ?.trim()
      ?.toLowerCase();
    if (val)
      return this.search({ candidateNo: val }).pipe(
        map((sr) =>
          sr?.content?.some((x) => x.candidateNo.toLowerCase() == val) ? null : { custom: `Invalid number` },
        ),
      );
    return of(null);
  };
}
type SaveDocParameter = { [code in ECandidateDocument]?: File | null };

interface IPinValidation {
  pin: string;
  rowId: string;
}
